import { ChainId } from "@desync-labs/rasa-lending-contract-helpers";
import { RASALendingLisk } from "@desync-labs/rasa-lending-address-book";
import { ReactNode } from "react";
import { SUBGRAPH_URLS } from "connectors/networks";

export type MarketDataType = {
  v3?: boolean;
  marketTitle: string;
  // the network the market operates on
  chainId: ChainId | number;
  enabledFeatures?: {
    faucet?: boolean;
    incentives?: boolean;
    permissions?: boolean;
    addressBlocked?: boolean;
    stableBorrowRate?: boolean;
    /**
     * Paraswap features
     */
    liquiditySwap?: boolean;
    collateralRepay?: boolean;
    debtSwitch?: boolean;
    withdrawAndSwitch?: boolean;
  };
  isFork?: boolean;
  permissionComponent?: ReactNode;
  disableCharts?: boolean;
  subgraphUrl?: string;
  addresses: {
    LENDING_POOL_ADDRESS_PROVIDER: string;
    LENDING_POOL: string;
    WETH_GATEWAY?: string;
    FAUCET?: string;
    PERMISSION_MANAGER?: string;
    WALLET_BALANCE_PROVIDER: string;
    UI_POOL_DATA_PROVIDER: string;
    COLLECTOR?: string;
    UI_INCENTIVE_DATA_PROVIDER?: string;
    /**
     * Paraswap addresses
     */
    DEBT_SWITCH_ADAPTER?: string;
    REPAY_WITH_COLLATERAL_ADAPTER?: string;
    SWAP_COLLATERAL_ADAPTER?: string;
    L2_ENCODER?: string;
    WITHDRAW_SWITCH_ADAPTER?: string;
  };
  /**
   * https://www.hal.xyz/ has integrated lending for healtfactor warning notification
   * the integration doesn't follow lending market naming & only supports a subset of markets.
   * When a halIntegration is specified a link to hal will be displayed on the ui.
   */
  halIntegration?: {
    URL: string;
    marketName: string;
  };
};

export enum CustomMarket {
  // v3 Lisk
  proto_lisk_v3 = "proto_lisk_v3",
}

export const marketsData: {
  [key in keyof typeof CustomMarket]: MarketDataType;
} = {
  [CustomMarket.proto_lisk_v3]: {
    marketTitle: "Lisk",
    v3: true,
    chainId: 1135,
    enabledFeatures: {
      faucet: false,
      incentives: false,
      addressBlocked: false,
      stableBorrowRate: false,
      /**
       * Paraswap features
       */
      liquiditySwap: false,
      collateralRepay: false,
      debtSwitch: false,
      withdrawAndSwitch: false,
    },
    subgraphUrl: `${
      SUBGRAPH_URLS[RASALendingLisk.CHAIN_ID]
    }/subgraphs/rasa-lending-subgraph/1.0.0/gn`,
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: RASALendingLisk.POOL_ADDRESSES_PROVIDER,
      LENDING_POOL: RASALendingLisk.POOL,
      WETH_GATEWAY: RASALendingLisk.WETH_GATEWAY,
      WALLET_BALANCE_PROVIDER: RASALendingLisk.WALLET_BALANCE_PROVIDER,
      UI_POOL_DATA_PROVIDER: RASALendingLisk.UI_POOL_DATA_PROVIDER,
      COLLECTOR: RASALendingLisk.COLLECTOR,
      UI_INCENTIVE_DATA_PROVIDER: RASALendingLisk.UI_INCENTIVE_DATA_PROVIDER,
    },
  },
} as const;
