import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

import TelegramSrc from "assets/svg/socials/telegram.svg";
import TwitterSrc from "assets/svg/socials/twitter.svg";
import LinkedInSrc from "assets/svg/socials/linkedln.svg";

const Footer = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  font-size: 1rem;
  color: #6495cc;
  border-top: 1px solid #cbd5e0;
  padding: 16px 40px;
`;

const LinksWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  a {
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    color: #3182ce;
    text-decoration: none;
    opacity: 1;
    display: flex;
    justify-content: start;
    padding: 0;

    &:hover {
      opacity: 0.8;
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    justify-content: center;
    gap: 18px 32px;
    padding: 16px 0;
  }
`;

const SocialLinksWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 0;

  & a {
    height: 20px;
  }

  & img {
    height: 20px;
    width: 20px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    gap: 20px;
    & a {
      height: 32px;
    }

    & img {
      height: 32px;
      width: 32px;
    }
  }
`;

const Copyright = function Copyright(props: any) {
  return (
    <Box>
      <Footer {...props}>
        <LinksWrapper>
          <a
            href={"https://docs.rasa.finance/"}
            rel="noreferrer"
            target={"_blank"}
          >
            Docs
          </a>
        </LinksWrapper>
        <SocialLinksWrapper>
          <a href={"#"} rel="noreferrer" target={"_blank"}>
            <img
              src={TelegramSrc as string}
              width={20}
              height={20}
              alt={"telegram"}
            />
          </a>
          <a href={"#"} rel="noreferrer" target={"_blank"}>
            <img
              src={TwitterSrc as string}
              width={20}
              height={20}
              alt={"twitter"}
            />
          </a>
          <a href={"#"} rel="noreferrer" target={"_blank"}>
            <img
              src={LinkedInSrc as string}
              width={20}
              height={20}
              alt={"linked-in"}
            />
          </a>
        </SocialLinksWrapper>
      </Footer>
    </Box>
  );
};

export default Copyright;
