import { styled } from "@mui/material/styles";
import { Box, CssBaseline, IconButton, Toolbar } from "@mui/material";
import { AccountBalanceWallet as AccountBalanceWalletIcon } from "@mui/icons-material";
import truncateEthAddress from "truncate-eth-address";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";

import { AppGlobalStyles } from "components/AppComponents/AppGlobalStyles/AppGlobalStyles";
import Copyright from "components/Footer/Footer";
import { BaseHeader } from "components/Base/Boxes/StyledBoxes";
import DashboardContent from "components/Dashboard/Dashboard";

import VaultsView from "components/Vaults/VaultsView";
import Web3Status from "components/Web3Status/Web3Status";

import AllProposalsView from "components/Governance/ViewAllProposals";
import ProposalView from "components/Governance/Proposal";
import Propose from "components/Governance/Propose";
import StakingView from "components/Staking/StakingView";
import AlertMessages from "components/Common/AlertMessages";
import TransactionStatus from "components/Transaction/TransactionStatus";
import DaoView from "components/Dashboard/DaoView";
import LendingView from "components/Dashboard/LendingView";
import FXDView from "components/Dashboard/FxdView";

import { MainBox } from "components/AppComponents/AppBox/AppBox";
import MobileConnector from "components/Dashboard/MobileConnector";
import DesktopConnector from "components/Dashboard/DesktopConnector";
import MobileMenu from "components/Dashboard/MobileMenu";
import TransactionErc20TokenModal from "components/Transaction/TransactionErc20TokenModal";

/**
 * Lending
 */
import Home from "apps/lending/pages/index.page";
import Markets from "apps/lending/pages/markets.page";
import History from "apps/lending/pages/history.page";
import ReserveOverview from "apps/lending/pages/reserve-overview.page";
import Faucet from "apps/lending/pages/faucet.page";

import useMainLayout from "hooks/General/useMainLayout";
import { StakingProvider } from "context/staking";
import { ProposalProvider } from "context/proposal";
import useConnector from "context/connector";
import useAlertAndTransactionContext from "context/alertAndTransaction";

import RasaAppLogoSrc from "assets/svg/rasa_logo_black.png";
import ExitSrc from "assets/svg/exit.svg";
import MetamaskSrc from "assets/svg/metamask.svg";
import WalletConnectSrc from "assets/svg/wallet-connect.svg";
import RasaLogoMobileSrc from "assets/svg/rasa-logo-mobile.png";
import MobileMenuIcon from "assets/svg/mobile-menu.svg";
import MobileMenuIconActive from "assets/svg/mobile-menu-active.svg";
import { formatNumber } from "utils/format";

import { memo, useEffect, useMemo } from "react";
import ReactGA from "react-ga4";
import VaultListView from "components/Vaults/VaultList/VaultListView";
import VaultTutorial from "components/Vaults/VaultTutorial/VaultTutorial";
import VaultDetailView from "components/Vaults/VaultDetail/VaultDetailView";

import PositionsTransactionList from "components/PositionActivityList/PositionActivityList";
import {
  ChainId,
  DISPLAY_FXD,
  DISPLAY_GOVERNANCE,
  DISPLAY_LENDING,
  DISPLAY_VAULTS,
  NETWORK_SETTINGS,
  supportedChainIds,
} from "connectors/networks";
import { DEFAULT_CHAIN_ID } from "utils/Constants";
import CookieConsent from "components/Common/CookieConsent";
import MaintenanceModeBanner from "components/Common/MaintenanceBanner";
import { FxdProvider } from "context/fxd";
import useVH from "hooks/General/useVH";
import ProposalDraftView from "components/Governance/ProposalDraftView";

const AccountInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
`;

export const AccountElement = styled("div")<{ active: string }>`
  display: flex;
  flex-direction: row;
  align-items: end;
  background-color: #354a72;
  border-radius: 12px;
  white-space: nowrap;
  cursor: pointer;
  color: #fff;
  margin-left: 10px;

  :focus {
    border: 1px solid blue;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: none;
  }
`;

const MenuLogoWrapper = styled(Box)`
  display: flex;
  width: 105px;
  align-items: center;
  justify-content: space-between;
`;

const MobileMenuWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 7px;
  font-size: 1rem;
`;

const WalletBox = styled(Box)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #094e6d;
  padding: 0 0 0 10px;
`;

const LogoLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const MainLayout = () => {
  const {
    scroll,
    disconnect,
    openMobile,
    account,
    error,
    isMobile,
    isActive,
    isMetamask,
    isWalletConnect,
    mainBlockClickHandler,
    openMobileMenu,
    setOpenMobile,
    userBalance,
  } = useMainLayout();

  const { chainId, openConnector, setOpenConnector, openConnectorMenu } =
    useConnector();

  const checkedChainId = useMemo(
    () => (supportedChainIds.includes(chainId) ? chainId : DEFAULT_CHAIN_ID),
    [chainId]
  );

  useVH();

  /**
   * Google Analytics
   */
  const { pathname, search } = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `${pathname}${search}` });
  }, [pathname, search]);

  const { erc20TokenModalData } = useAlertAndTransactionContext();
  const isConnected = localStorage.getItem("isConnected");

  return (
    <AppGlobalStyles>
      <Box sx={{ display: "flex" }} onClick={mainBlockClickHandler}>
        <CssBaseline />
        <BaseHeader position="absolute">
          <Toolbar
            sx={{
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {isMobile ? (
              <MenuLogoWrapper>
                <LogoLink to={"/"}>
                  <img
                    src={RasaLogoMobileSrc}
                    alt={"logo"}
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </LogoLink>
                <MobileMenuWrapper
                  onClick={openMobile ? mainBlockClickHandler : openMobileMenu}
                >
                  <img
                    style={{ display: openMobile ? "none" : "block" }}
                    src={MobileMenuIcon}
                    alt={"menu"}
                    width={20}
                    height={20}
                  />
                  <img
                    style={{ display: openMobile ? "block" : "none" }}
                    src={MobileMenuIconActive}
                    alt={"menu"}
                    width={20}
                    height={20}
                  />
                  Apps
                </MobileMenuWrapper>
              </MenuLogoWrapper>
            ) : (
              <LogoLink to={"/"}>
                <img
                  src={RasaAppLogoSrc}
                  alt={"logo"}
                  style={{
                    height: "none",
                    maxWidth: "140px",
                  }}
                />
              </LogoLink>
            )}

            <AccountInfoWrapper>
              <Web3Status />

              {isMetamask && <img src={MetamaskSrc} alt={"metamask"} />}
              {isWalletConnect && (
                <img src={WalletConnectSrc} alt={"wallet-connect"} />
              )}
              <AccountElement
                active={account ? "true" : "false"}
                style={{ pointerEvents: "auto" }}
              >
                {account && userBalance ? (
                  <Box
                    fontSize={"1rem"}
                    sx={{ flexShrink: 0 }}
                    p="0.5rem 0.5rem 0.5rem 0.5rem"
                    fontWeight={500}
                  >
                    {formatNumber(Number(userBalance))}{" "}
                    {NETWORK_SETTINGS[chainId as ChainId]?.nativeCurrency
                      ?.symbol ||
                      NETWORK_SETTINGS[DEFAULT_CHAIN_ID]?.nativeCurrency
                        ?.symbol}
                  </Box>
                ) : null}
              </AccountElement>
              {account && !error && (
                <WalletBox>{truncateEthAddress(account)}</WalletBox>
              )}

              <IconButton
                color="inherit"
                onClick={isActive || error ? disconnect : openConnectorMenu}
              >
                {isActive ? (
                  <img src={ExitSrc} alt={"exit"} />
                ) : (
                  <AccountBalanceWalletIcon sx={{ color: "#476bae" }} />
                )}
              </IconButton>
            </AccountInfoWrapper>
          </Toolbar>
        </BaseHeader>
        <MainBox component="main" className={isConnected ? "loggedIn" : ""}>
          <Box>
            <Toolbar />
            <AlertMessages scroll={scroll} />
            <TransactionStatus scroll={scroll} />
            <FxdProvider>
              <Routes>
                {DISPLAY_FXD.includes(checkedChainId) ? (
                  <Route path="/fxd" element={<FXDView />}>
                    <>
                      <Route path="/fxd" element={<DashboardContent />} />
                      {process.env.REACT_APP_FXD_TRANSACTIONS_ENABLED ===
                        "true" &&
                        (account || isConnected) && (
                          <Route
                            path="/fxd/transactions"
                            element={<PositionsTransactionList />}
                          />
                        )}
                      <Route
                        path="*"
                        element={<Navigate to="/fxd" replace />}
                      />
                    </>
                  </Route>
                ) : null}
                {DISPLAY_GOVERNANCE.includes(checkedChainId) ? (
                  <Route path="/dao" element={<DaoView />}>
                    <Route
                      index
                      element={
                        <StakingProvider>
                          <StakingView />
                        </StakingProvider>
                      }
                    />
                    <Route
                      path="staking"
                      index
                      element={
                        <StakingProvider>
                          <StakingView />
                        </StakingProvider>
                      }
                    />
                    <Route
                      path="governance"
                      element={<AllProposalsView />}
                    ></Route>
                    <Route
                      path="governance/drafts"
                      element={<AllProposalsView />}
                    ></Route>
                    <Route
                      path="governance/drafts/:_proposalId"
                      element={<ProposalDraftView />}
                    />
                    <Route
                      path="governance/proposal/:_proposalId"
                      element={
                        <ProposalProvider>
                          <ProposalView />
                        </ProposalProvider>
                      }
                    />
                    <Route
                      path="governance/proposal/create"
                      element={<Propose />}
                    />
                    <Route
                      path="governance/proposal/create/:_proposalId"
                      element={<Propose />}
                    />
                    <Route
                      path="*"
                      element={<Navigate to="/dao/staking" replace />}
                    />
                  </Route>
                ) : null}
                {DISPLAY_LENDING.includes(checkedChainId) ? (
                  <Route path="/lending" element={<LendingView />}>
                    <Route index element={<Home />} />
                    <Route path="markets" element={<Markets />} />
                    <Route
                      path="reserve-overview"
                      element={<ReserveOverview />}
                    />
                    <Route path="transactions" element={<History />} />
                    <Route path="faucet" element={<Faucet />} />
                    <Route
                      path="*"
                      element={<Navigate to="/lending" replace />}
                    />
                  </Route>
                ) : null}
                {DISPLAY_VAULTS.includes(checkedChainId) ? (
                  <Route path="/vaults" element={<VaultsView />}>
                    <Route index element={<VaultListView />} />
                    <Route path="tutorial" index element={<VaultTutorial />} />
                    <Route
                      path=":vaultAddress/*"
                      element={<VaultDetailView />}
                    />
                  </Route>
                ) : null}
                <Route path="*" element={<Navigate to="/lending" replace />} />
              </Routes>
            </FxdProvider>
          </Box>
          <Copyright />
        </MainBox>
      </Box>
      {isMobile && openMobile && <MobileMenu setOpenMobile={setOpenMobile} />}
      {isMobile && openConnector && (
        <MobileConnector setOpenMobileConnector={setOpenConnector} />
      )}
      {!isMobile && openConnector && (
        <DesktopConnector onClose={() => setOpenConnector(false)} />
      )}

      {erc20TokenModalData && <TransactionErc20TokenModal />}
      <CookieConsent />
      {process.env.REACT_APP_MAINTENANCE_MODE === "true" && (
        <MaintenanceModeBanner />
      )}
    </AppGlobalStyles>
  );
};

export default memo(MainLayout);
