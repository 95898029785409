import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "connectors/wallet-connect-connector/WalletConnectConnector";
import { EthereumProviderOptions } from "@walletconnect/ethereum-provider/dist/types/EthereumProvider";
import { JsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import { DEFAULT_CHAIN_ID } from "utils/Constants";

export const SEPOLIA_RPC = "https://eth-sepolia.public.blastapi.io/";
export const LISK_RPC = "https://lisk.drpc.org/";

export enum ChainId {
  LISK = 1135,
  SEPOLIA = 11155111,
}

const SUBGRAPH_URLS = {
  [ChainId.LISK]:
    "https://api.goldsky.com/api/public/project_cm1iz49aoi6n601vg8nbn6r0q",
  [ChainId.SEPOLIA]: "https://graph.sepolia.fathom.fi",
};

let supportedChainIds = [ChainId.SEPOLIA];
const NATIVE_ASSETS = ["ETH"];

let DEFAULT_RPCS: any = {
  [ChainId.SEPOLIA]: SEPOLIA_RPC,
};

let NETWORK_SETTINGS: { [n: number]: any } = {
  [ChainId.SEPOLIA]: {
    chainName: "Sepolia",
    chainId: `0x${ChainId.SEPOLIA.toString(16)}`,
    nativeCurrency: { name: "SepoliaETH", decimals: 18, symbol: "ETH" },
    rpcUrls: [SEPOLIA_RPC],
    logoName: "ETH",
  },
};

if (process.env.REACT_APP_ENV === "prod") {
  supportedChainIds = [ChainId.LISK];

  DEFAULT_RPCS = {
    [ChainId.LISK]: LISK_RPC,
  };

  NETWORK_SETTINGS = {
    [ChainId.LISK]: {
      chainName: "Lisk",
      chainId: `0x${ChainId.LISK.toString(16)}`,
      nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
      rpcUrls: [LISK_RPC],
      blockExplorerUrls: ["https://blockscout.lisk.com"],
      logoName: "LISK",
    },
  };
}

export type DefaultProvider = Web3Provider | JsonRpcProvider;

export const EXPLORERS = {
  [ChainId.LISK]: "https://blockscout.lisk.com/",
  [ChainId.SEPOLIA]: "https://sepolia.etherscan.io/",
};

export const injected = new InjectedConnector({ supportedChainIds });

export const WalletConnect = new WalletConnectConnector({
  chains: [DEFAULT_CHAIN_ID],
  optionalChains: supportedChainIds,
  rpcMap: DEFAULT_RPCS,
  showQrModal: true,
  projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
  qrModalOptions: {
    themeVariables: {
      "--wcm-z-index": "10000",
    },
  },
} as unknown as EthereumProviderOptions);

export {
  NATIVE_ASSETS,
  NETWORK_SETTINGS,
  supportedChainIds,
  SUBGRAPH_URLS,
  DEFAULT_RPCS,
};

/**
 * Display settings for the different sections of the app
 */
export const DISPLAY_FXD = [ChainId.SEPOLIA];
export const DISPLAY_LENDING = [ChainId.SEPOLIA, ChainId.LISK];
export const DISPLAY_VAULTS = [ChainId.SEPOLIA];
export const DISPLAY_GOVERNANCE = [ChainId.SEPOLIA];
