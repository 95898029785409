import { useCallback, useEffect, useState, MouseEvent } from "react";
import BigNumber from "bignumber.js";
import useConnector from "context/connector";
import { useLocation } from "react-router-dom";
import useSharedContext from "context/shared";
import { BigNumber as eBigNumber } from "ethers";

const useMainLayout = () => {
  const { isMobile } = useSharedContext();
  const {
    chainId,
    library,
    disconnect,
    isActive,
    account,
    error,
    isMetamask,
    isWalletConnect,
    openConnector,
    setOpenConnector,
  } = useConnector();

  const [openMobile, setOpenMobile] = useState<boolean>(false);
  const currentPath = useLocation();
  const [scroll, setScroll] = useState<number>(0);

  const [userBalance, setUserBalance] = useState<null | string>(null);

  const scrollHandler = useCallback(() => {
    setScroll(window.scrollY);
  }, [setScroll]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (chainId) {
      timeout = setTimeout(() => {
        library.getBalance(account).then((balance: eBigNumber) => {
          setUserBalance(
            BigNumber(balance.toString()).dividedBy(1e18).toString()
          );
        });
      }, 100);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [library, account, chainId]);

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler);
    return () => {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, [scrollHandler]);

  useEffect(() => {
    if (isMobile) {
      const inputs = document.querySelectorAll('input[type="number"]');
      for (let i = inputs.length; i--; ) {
        inputs[i].setAttribute("pattern", "\\d*");
      }
    }
  }, [currentPath, isMobile]);

  const mainBlockClickHandler = useCallback(() => {
    if (isMobile && (openMobile || openConnector)) {
      setOpenMobile(false);
      setOpenConnector(false);
    }
  }, [isMobile, openMobile, openConnector, setOpenMobile, setOpenConnector]);

  const openMobileMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();

      setOpenMobile(true);
    },
    [setOpenMobile]
  );

  return {
    scroll,
    account,
    error,
    isMobile,
    isActive,
    disconnect,
    isMetamask,
    isWalletConnect,
    openMobile,
    setOpenMobile,
    openConnector,
    setOpenConnector,
    mainBlockClickHandler,
    openMobileMenu,
    userBalance,
  };
};

export default useMainLayout;
