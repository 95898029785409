import { Dispatch, FC, memo } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Menu } from "components/Dashboard/Menu";

export const MobileMenuWrapper = styled(Box)`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: fit-content;
  background: #efeeed;
  box-shadow: 0 14px 33px 21px rgba(0, 0, 0, 0.12),
    0 16px 12px 2px rgba(0, 0, 0, 0.14), 0 6px 15px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 20px;
  > a {
    margin-bottom: 14px;
  }
`;

type MobileMenuPropsType = {
  setOpenMobile: Dispatch<boolean>;
};

const MobileMenu: FC<MobileMenuPropsType> = memo(({ setOpenMobile }) => {
  return (
    <MobileMenuWrapper onClick={() => setOpenMobile(false)}>
      <Menu open={true} />
    </MobileMenuWrapper>
  );
});

export default MobileMenu;
