import { Contract } from "@ethersproject/contracts";
import { getAddress } from "@ethersproject/address";
import { AddressZero } from "@ethersproject/constants";
import { JsonRpcSigner, Web3Provider } from "@ethersproject/providers";
import { BigNumber } from "@ethersproject/bignumber";

import { ChainId } from "connectors/networks";

export const toXdcAddress = (address: string) => {
  return address.replace("0x", "xdc");
};

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

const BLOCKSCAN_DOMAIN: { [chainId in ChainId]: string } = {
  [ChainId.SEPOLIA]: "sepolia.etherscan.io",
  [ChainId.LISK]: "blockscout.lisk.com",
};

export function getBlockScanLink(
  chainId: ChainId,
  data: string,
  type:
    | "transaction"
    | "token"
    | "address"
    | "block"
    | "transactions"
    | "tokens"
    | "blocks"
): string {
  const prefix = `https://${BLOCKSCAN_DOMAIN[chainId]}`;

  switch (type) {
    case "transaction": {
      return `${prefix}/txs/${data}`;
    }
    case "token": {
      return `${prefix}/tokens/${toXdcAddress(data)}`;
    }
    case "block": {
      return `${prefix}/blocks/${data}`;
    }
    case "address":
    default: {
      return `${prefix}/address/${toXdcAddress(data)}`;
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  let parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  parsed = toXdcAddress(parsed);
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value
    .mul(BigNumber.from(10000).add(BigNumber.from(1000)))
    .div(BigNumber.from(10000));
}

// account is not optional
export function getSigner(
  library: Web3Provider,
  account: string
): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account) as any
  );
}
