import {
  createContext,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useServices } from "context/services";
import useSyncContext from "context/sync";
import useConnector from "context/connector";

type PricesProviderType = {
  children: ReactElement;
};

export type UsePricesContextReturn = {
  rasaUsdPrice: string;
  ethPrice: string;
  rasaPrice: string;
  prevEthPrice: string | null;
  fetchPricesInProgress: boolean;
};

export const PricesContext = createContext<UsePricesContextReturn>(
  {} as UsePricesContextReturn
);

export const PricesProvider: FC<PricesProviderType> = ({ children }) => {
  const { provider } = useServices();
  const { chainId } = useConnector();

  const [rasaUsdPrice, setRasaUsdPrice] = useState<string>("0");
  const [ethPrice, setEthPrice] = useState<string>("0");
  const [prevEthPrice, setPrevEthPrice] = useState<string | null>(null);
  const [rasaPrice, setRasaPrice] = useState<string>("0");
  const [fetchPricesInProgress, setFetchPricesInProgress] =
    useState<boolean>(false);

  const {
    syncDao,
    prevSyncDao,
    syncFXD,
    prevSyncFxd,
    syncVault,
    prevSyncVault,
  } = useSyncContext();

  const fetchPairPrices = useCallback(async () => {
    // todo: add fetch prices
    setFetchPricesInProgress(true);
    setPrevEthPrice("0");
    setEthPrice("0");
    setRasaPrice("0");
    setRasaUsdPrice("0");
    setFetchPricesInProgress(false);
  }, [
    chainId,
    provider,
    setEthPrice,
    setRasaPrice,
    setRasaUsdPrice,
    setPrevEthPrice,
    setFetchPricesInProgress,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      !fetchPricesInProgress && fetchPairPrices();
    }, 100);

    return () => timeout && clearTimeout(timeout);
  }, [fetchPairPrices]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (
      (syncFXD && !prevSyncFxd) ||
      (syncDao && !prevSyncDao) ||
      (syncVault && !prevSyncVault)
    ) {
      timeout = setTimeout(() => {
        fetchPairPrices();
      }, 300);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [syncFXD, prevSyncFxd, syncDao, prevSyncDao, syncVault, prevSyncVault]);

  const values = useMemo(() => {
    return {
      ethPrice,
      rasaPrice,
      rasaUsdPrice,
      prevEthPrice,
      fetchPricesInProgress,
    };
  }, [fetchPricesInProgress, ethPrice, rasaPrice, rasaUsdPrice, prevEthPrice]);

  return (
    <PricesContext.Provider value={values}>{children}</PricesContext.Provider>
  );
};

const usePricesContext = () => useContext(PricesContext);

export default usePricesContext;
